body {
    margin: 0;
    padding: 25px 5% 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::selection {
    background-color: #0077ff;
    color: white;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem
}

.rightNav {
    display: flex;
    gap: 10px;
}

@keyframes in-keyframes {
    0% {
        opacity: 0;
        transform: translate(0, 100%);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes in-keyframes-slow {
    0% {
        opacity: 0;
        transform: translate(0, 40%);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.animate-in {
    animation-name: in-keyframes;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.slow-animate-in {
    animation-name: in-keyframes-slow;
    animation-duration: 1s;
}

body .react-bubbly-transitions__bubbly-link {
    padding: 0;
    padding-top: 5px;
}

body .react-bubbly-transitions__bubbly-link.active {
    padding: 0;
    position: relative;
    color: #0077ff;
}

body .react-bubbly-transitions__bubbly-link.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-radius: 3px;
    background: #0077ff;
}

.hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.container {
    margin: 0 auto;
    max-width: 107rem;
    padding: 0 4rem;
}

.hero-main {
    align-items: center;
    display: flex;
    gap: 10rem;
    justify-content: center;
    position: relative;
}

.hero-text {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    position: relative;
}

.hero-text h1 {
    color: #2d2e32;
    font-size: 5.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.hero-text p {
    color: #555;
    font-family: Mulish, sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.6;
}

.hero-img {
    -webkit-animation: morph 8s ease-in-out infinite;
    animation: morph 8s ease-in-out infinite;
    background-image: url(images/profile.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 3px solid #2d2e32;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    height: 20rem;
    width: 20rem;
    position: relative;
    transition: all 1s ease-in-out;
}

.hero-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.hero-text h1 {
    color: #2d2e32;
    font-size: 4rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.hero-text p {
    color: #555;
    font-family: Mulish, sans-serif;
    font-size: 1.3rem;
}

.hero-text span {
    display: flex;
    gap: 1.5rem;
    margin: 1rem 0;
    align-items: center;
}

.hero-text span a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #2d2e32;
    width: auto;
    height: 2.5rem;
}

.hero-text span svg {
    width: 2.5rem;
    height: 2.5rem;
    transition: fill 0.3s ease;
}

.hero-text span svg:hover {
    fill: #0077ff;
}

.hero-img {
    -webkit-animation: morph 8s ease-in-out infinite;
    animation: morph 8s ease-in-out infinite;
    background-image: url(images/profile.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 3px solid #2d2e32;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    height: 20rem;
    width: 20rem;
    position: relative;
    transition: all 1s ease-in-out;
}

.skills {
    align-items: center;
    bottom: -170px;
    color: #767676;
    display: flex;
    font-size: 1.7rem;
    left: 0;
    position: absolute;
}

.skills p {
    border-right: 2px solid rgba(45, 46, 50, 0.5);
    color: #2d2e32;
    font-family: Mulish, sans-serif;
    font-weight: 600;
    margin-right: 2rem;
    padding-right: 2rem;
}

.skills ul {
    display: flex;
    flex-wrap: wrap;
    gap: 2.2rem;
    list-style: none;
}

.skills ul li {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    display: flex;
    height: 5.5rem;
    justify-content: center;
    width: 5.5rem;
}

.skills ul li img {
    height: 3rem;
    width: 3rem;
}

.about {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.about-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.about-img {
    background-image: url(images/about-img.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10%;
    height: 20rem;
    width: 20rem;
    position: relative;
    transition: all 1s ease-in-out;
}

.about-text {
    width: 100%;
    font-size: 18px;
}

.link-stratus {
    font-weight: 600;
    color: black;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

.link-stratus:hover {
    color: #3240b7;
}

.extra-skills {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.extra-skills-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}

.skill-card {
    min-height: 13rem;
    margin-top: 1rem;
    border: solid #e5e7eb 1px;
    border-radius: 1rem;
    padding: 1rem;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.skill-card:hover {
    border-color: #373738;
}

.skill-card > svg {
    width: 2em;
}

.skill-card > p {
    font-size: 14px;
    text-overflow: clip;
    max-width: 15rem;
}

.skill-card > p:nth-child(2) {
    font-weight: 500;
    font-size: 20px;
    text-overflow: clip;
}

.certification-card {
    min-height: 13rem;
    min-width: 15rem;
    margin-top: 1rem;
    border: solid #e5e7eb 1px;
    border-radius: 1rem;
    padding: 1rem;
    cursor: pointer;
    transition: border-color 0.3s ease;
    text-decoration: none;
    color: inherit;

}

.certification-card:hover {
    border-color: #373738;
}

.certification-card > svg {
    width: 2em;
}

.certification-card > p {
    font-size: 14px;
    text-overflow: clip;
    max-width: 15rem;
}

.certification-card > p:nth-child(2) {
    font-weight: 500;
    font-size: 20px;
    text-overflow: clip;
}

.button-full {
    padding: 0.75rem 1.5rem;
    font-size: 16px;
    background-color: black;
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
}

.button-outline {
    padding: 0.75rem 1.5rem;
    font-size: 16px;
    background-color: transparent;
    border: solid #e5e7eb 2px;
    color: #919295;
    border-radius: 0.5rem;
    cursor: pointer;
}

.buttons {
    display: flex;
    gap: 0.5rem;
}

.projects-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: solid #e5e7eb 1px;
    border-radius: 1rem;
    padding: 2rem;
    align-items: center;
}

.project-reverse {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    border: solid #e5e7eb 1px;
    border-radius: 1rem;
    padding: 2rem;
    align-items: center;

}

.project > div > p & & .project-reverse > div > p {
    color: #767676;
}

.project-image {
    border-radius: 20px;
    height: auto;
    object-fit: contain;
    width: 43rem;
    padding: 10px;
}

.project-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-text > h3 {
    margin: 0;
}

.project-text > p {
    font-size: 16px;
    font-weight: 100;
}

.live-container {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
}

.live-container > a {
    color: #2d2e32;
    display: flex;
    justify-items: start;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    gap: 0.5rem;
    text-decoration: none;
}

.live-container > a:hover {
    color: #147efb;
    fill: #147efb;
    transition: all 0.3s;
}

.live-container > a > svg {
    width: 30px;
    height: 30px;
}

.technologies {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.styled-word {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.375rem;
    background-color: #f3f4f6;
    color: #4b5563;
    border: none;
}


.extra-skills-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
    50% {
        border-radius: 40% 60% 70% 30% / 30% 70% 40% 60%;
    }
    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}

@media (min-width: 1200px) {
    body .container {
        max-width: 114rem;
    }

    .hero-main {
        margin-top: 3rem;
        display: flex;
        gap: 8rem;
    }

    .hero-text h1 {
        font-size: 4rem;
    }

    .hero-text p {
        font-size: 1.5rem;
    }

    .about-container {
        flex-direction: row;
        gap: 4rem;
    }

    .about-img {
        height: 20rem;
        width: 20rem;
    }

    .about-text {
        width: 40%;
        font-size: 18px;
    }

    .extra-skills-buttons {
        grid-template-columns: repeat(3, 1fr);
    }

    .projects-container {
        margin: 3rem 15rem;
    }

    .project {
        flex-direction: row;
        min-width: 64rem;
    }

    .project-reverse {
        flex-direction: row;
        min-width: 64rem;
    }

    .project-image {
        outline: 2px solid #e5e7eb;
    }

    .button-full:hover {
        padding: 0.75rem 1.5rem;
        font-size: 16px;
        background-color: transparent;
        border-color: black;
        color: black;
        border-radius: 0.5rem;
        cursor: pointer;
    }

    .button-outline:hover {
        padding: 0.75rem 1.5rem;
        font-size: 16px;
        background-color: transparent;
        border-color: black;
        color: black;
        border-radius: 0.5rem;
        cursor: pointer;
    }

    .button-full {
        transition: background-color 0.3s, color 0.3s, outline 0.3s;
    }

    .button-outline {
        transition: background-color 0.3s, color 0.3s, outline 0.3s;
    }

    .extra-skills-title {
        flex-direction: row;
        justify-content: space-between;
        min-width: 55rem;
    }
}

@media (max-width: 1199px) and (min-width: 768px) {
    .hero-main {
        gap: 5rem;
    }

    .hero-text p {
        font-size: 1.6rem;
    }

    .hero-text span a {
        font-size: 3rem;
    }

    .skills {
        bottom: -250px;
    }

    .about-img {
        height: 25rem;
        width: 25rem;
    }

    .about-text {
        width: 70%;
        font-size: 16px;
    }

    .extra-skills-buttons {
        grid-template-columns: repeat(2, 1fr);
    }

    .project-text {
        min-width: 28rem;
        max-width: 10rem;
    }
}

@media (max-width: 900px) {
    body .container {
        padding: 0 2rem;
    }

    .react-bubbly-transitions__bubbly-link:active {
        color: blueviolet;
    }

    .hero-main {
        flex-direction: column;
        gap: 1rem;
    }

    .hero-text {
        align-items: center;
    }

    .hero-text h1 {
        font-size: 3rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    .hero-text p {
        font-size: 1.2rem;
        text-align: center;
    }

    .hero-text span a {
        font-size: 2rem;
    }

    .hero-text span {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .hero-img {
        height: 15rem;
        width: 15rem;
    }

    .skills {
        flex-direction: column;
        position: static;
    }

    .skills p {
        border-bottom: 2px solid rgba(45, 46, 50, 0.5);
        border-right: none;
        margin-bottom: 3rem;
        margin-right: 0;
        padding-bottom: 1rem;
        padding-right: 0;
    }

    .skills ul {
        justify-content: center;
        padding-left: 0rem;
    }

    .about-container {
        gap: 3rem;
    }

    .about-img {
        height: 15rem;
        width: 15rem;
    }

    .about-text {
        width: 75%;
        font-size: 14px;
    }

    .extra-skills-buttons {
        grid-template-columns: 1fr;
    }

    .project-image {
        width: 100%;
        border: none;
    }

    .live-container {
        justify-content: center;
    }
}

.input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

input {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
}

input:focus {
    border-color: #0077ff;
}

.icon-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.icon-container svg {
    transition: opacity 0.3s ease;
}

.icon-container svg:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.icon-container svg:first-child {
    opacity: 1;
}

.copy-icon {
    width: 20px;
    height: 20px;
    fill: #fff;
}

.copy-message {
    color: green;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}
